import React from "react"
import Layout from "../components/layout"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/seo"
import { PageHeader, Services } from "../components/sections"
import {
  continuityservices,
  daas,
  desktopservices,
  enduseritservices,
  itservices,
  remotesupport,
  remoteworker,
  Content,
} from "../images/manageditservices"
import {
  BlackSection,
  DetailSection,
  IntroSection,
  OfferSection,
  Points,
} from "../components/structure"
import { AboutCard } from "../components/elements"
import { managedItServices } from "../images/cardicons"

const weOffer = [
  {
    id: 1,
    src: remotesupport,
    title: "Remote IT Support",
    content:
      "We provide 24/7 remote IT support, monitoring, and maintenance. Our experienced team offers technical support to resolve any issues and advises on the management of hardware devices. Our expertise means greater accuracy and reduced risks for your business.",
    order: 0,
  },
  {
    id: 2,
    src: remoteworker,
    title: "Remote Worker Support",
    content:
      "Remote support has never been more essential. That’s why we are always on stand-by to offer worker support through any technical issues that may arise. Our team is patient, professional, and above all, experienced. We’ll be there when you need us!",
    order: 1,
  },
  {
    id: 3,
    src: itservices,
    title: "End-User IT Services",
    content:
      "We specialise in end-user IT services, setting up, monitoring and managing the technology that your business relies on to grow and thrive. Let us take care of your everyday IT needs so that you can concentrate on running a successful business.",
    order: 0,
  },
  {
    id: 4,
    src: desktopservices,
    title: "Managed Desktop Services",
    content:
      "Data backup and recovery, mobile device management, cyber security … these administrative technical tasks are time-consuming, so let us take them off your hands. The managed desktop services we provide improve the overall security of your business while minimising risks and giving you peace of mind that the technical side is running smoothly.",
    order: 1,
  },
  {
    id: 5,
    src: daas,
    title: "Desktop-as-a-Service (DaaS)",
    content:
      "DaaS will solve several issues that your business may have to deal with, ranging from data security to software migration to device upgrades to app accessibility. This ongoing service covers technical issues like mobility and IT operations, allowing you to get back to growing your business.",
    order: 0,
  },
  {
    id: 6,
    src: enduseritservices,
    title: "Centralised IT Services",
    content:
      "Our centralised IT services will give you more control over your business. This process involves automatically implementing security and application updates onto your business devices so that they are compliant and accessible for your workers. We have the technology and resources to seamlessly take care of this for you so you don’t have to rely on costly in-house personnel.",
    order: 1,
  },
  {
    id: 7,
    src: continuityservices,
    title: "Business Continuity Services",
    content:
      "It only takes one technical fault to lead to a business disaster. That’s where our business continuity services come in. We apply tried-and-tested technology and strategies to provide disaster recovery in the event of an emergency, and work closely with you to determine a tailored plan that suits your business while reducing any possible costs.",
    order: 0,
  },
]

const points = [
  {
    id: 1,
    point: "Ongoing technical support",
  },
  {
    id: 2,
    point: "Increased security, mobility, and accessibility",
  },
  {
    id: 3,
    point: "Automatic updates and a Standard Operating Environment",
  },
  {
    id: 4,
    point: "Technical disaster recovery",
  },
  {
    id: 5,
    point: "Reduced costs, risks, and liabilities",
  },
  {
    id: 6,
    point: "And much more",
  },
]
export default function ManagedITServices() {
  const data = useStaticQuery(graphql`
    query {
      index: file(relativePath: { eq: "headers/manageProduct.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const index = [
    data.index.childImageSharp.fluid,
    `linear-gradient(0deg,
      rgba(0, 0, 0, 0.30),
      rgba(0, 0, 0, 0.30))`,
  ].reverse()
  return (
    <Layout>
      <SEO title="Managed IT Services" />
      <PageHeader
        fluid={index}
        catchy="Managed IT Services"
        subtitle="24/7 support so you can grow your business"
        title="We’ve got all your IT needs covered."
      />
      <IntroSection
        title="Managed IT Services"
        content="In the digital age, managed IT services make your business a lot easier to run. Our qualified team uses only the most advanced technology to upkeep your company’s servers, workstations, and other IT infrastructure, so you won’t have to worry about a thing. Allow us to take care of all your company’s technology requirements and let you get back to what you do best: focusing on your business."
        src={managedItServices}
      />
      <BlackSection title="Book today for unlimited IT support" />
      <IntroSection
        title="How we will help you?"
        content="With 15 years of industry experience behind us, we are confident IT experts. Our cost-effective managed IT services provide answers to all the day-to-day technical needs that your business will face. Allowing us to take care of these for you will lead to increased productivity and more savings for your business. From remote IT and worker support to managed desktop services to business continuity strategies, we have everything covered so you can put your time and energy into running your organisation."
        src={Content}
      />
      <OfferSection title="What we offer:">
        {weOffer.map((item, id) => (
          <AboutCard
            key={item.id}
            order={item.order}
            src={item.src}
            to={item.to}
            title={item.title}
            content={item.content}
          />
        ))}
      </OfferSection>
      <DetailSection
        paragraph1="With so many businesses relying on technology to run smoothly, managed IT services are more valuable than ever. By outsourcing your company’s IT to us, you and your workers can simply focus on getting the job done without any distractions. All in all, our managed IT services will give you:"
        paragraph2="These benefits lead to more control over your business and the peace of mind that you always have reliable support at your fingertips. Most importantly, they give you more time to focus on your business, leading to increased productivity and growth."
      >
        {points.map((item, id) => (
          <Points key={item.id}>{item.point}</Points>
        ))}
      </DetailSection>
      <Services />
      <BlackSection title="Call us today to find out how our managed IT services can help you." />
    </Layout>
  )
}
